import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './dailygames.module.scss';
import CountdownTimer from './CountdownTimer';
import { Game } from './Game';
import { withErrorBoundary } from '@buzzfeed/react-components';

const MemoizedGame = memo(Game);
MemoizedGame.displayName = 'MemoizedGame';

const TimerContainer = memo(() => (
  <div className={styles.header}>
    <h2 className={styles.arcadeHeader}>Daily Games</h2>
    <CountdownTimer />
  </div>
));
TimerContainer.displayName = 'TimerContainer';

const GamesContainer = memo(({ items, trackingData }) => (
  <div className={styles.games}>
    {items.map((game, index) => (
      <MemoizedGame
        key={game.id || index}
        game={game}
        index={index}
        trackingData={trackingData}
      />
    ))}
  </div>
));
GamesContainer.displayName = 'GamesContainer';

const DailyGames = ({ items, trackingData = {} }) => {
  if (!items?.length) return null;

  return (
    <div className={styles.dailyGamesZoneWrapper}>
      <TimerContainer />
      <GamesContainer items={items} trackingData={trackingData} />
    </div>
  );
};

DailyGames.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    content: PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.shape({
        square: PropTypes.string,
        square_alt_text: PropTypes.string
      }).isRequired
    }).isRequired
  })).isRequired,
  trackingData: PropTypes.object
};

export default withErrorBoundary(DailyGames);
