import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTrackingContext, useViewImpression } from '../../../hooks';
import { StreakIcon } from '../../../icons/StreakIcon';
import { getStreakCount } from './utils/streakUtils';
import { snakeCase } from './utils/stringUtils';
import styles from './dailygames.module.scss';

const Streak = ({ days }) => {
  if (!days) return null;
  return <span className={styles.streak}><StreakIcon /> {days}</span>;
};

export const Game = ({ game, index, trackingData }) => {
  const { trackInternalLink } = useTrackingContext();
  const [gameData, setGameData] = useState({
    pyramidScheme: null,
    dailyTrivia: null
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;

    setGameData({
      pyramidScheme: JSON.parse(localStorage.getItem('pyramidScheme')),
      dailyTrivia: JSON.parse(localStorage.getItem('dailyTrivia'))
    });
  }, []);

  const streakByGameName = {
    'Pyramid Scheme': getStreakCount(gameData.pyramidScheme, 'lastSeen'),
    'Daily Trivia': getStreakCount(gameData.dailyTrivia, 'lastPlayed')
  };

  const sharedTrackingData = {
    ...game.trackingData,
    subunit_type: 'component',
    subunit_name: `${game.object_type}|${game.id}`,
    ...trackingData,
    position_in_unit: index,
    item_name: snakeCase(game.content.title),
    target_content_id: snakeCase(game.content.title)
  };

  const { setObservable } = useViewImpression({ trackingData: sharedTrackingData });

  return (
    <div className={styles.game}>
      <a
        ref={obvEl => setObservable(obvEl)}
        href={game.content.url}
        className={styles.imgWrapper}
        onClick={() => trackInternalLink(sharedTrackingData)}
      >
        {game.content.highlight && (
          <div className={styles.highlighted}><span>{game.content.highlight}</span></div>
        )}
        {streakByGameName[game.content.title] > 0 && (
          <Streak days={streakByGameName[game.content.title]} />
        )}
        <img
          className={styles.gameImage}
          src={game.content.title === 'Lil Snack Games'
            ? 'https://www.lilsnack.co/assets/buzzfeed-thumbnail-small.png'
            : game.content.images.square}
          alt={game.content.images.square_alt_text}
        />
        <div className={styles.titleWrapper}>
          <h2 className={styles.gameTitle}>
            {game.content.title}
          </h2>
        </div>
      </a>
    </div>
  );
};

Game.propTypes = {
  game: PropTypes.shape({
    id: PropTypes.string,
    object_type: PropTypes.string.isRequired,
    trackingData: PropTypes.object,
    content: PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.shape({
        square: PropTypes.string.isRequired,
        square_alt_text: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  index: PropTypes.number.isRequired,
  trackingData: PropTypes.object
};


Streak.propTypes = {
  days: PropTypes.number
};
