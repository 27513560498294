import React, { useState, useEffect, useRef } from 'react';
import { CaretLeftIcon, CaretRightIcon } from '@buzzfeed/react-components';
import styles from './newInArcade.module.scss';
import { useMediaQuery, useTrackingContext, useViewImpression } from '../../../hooks';
import ImagePlaceholder from '../../../static/img/image-placeholder.svg';

const BFSlide = ({ slide, index, commonTrackingData, className }) => {
  const { trackInternalLink } = useTrackingContext();
  const { object_type: objectType, trackingData: algorithmTrackingData } = slide;
  const contentObjectId = slide.id;
  const subunitTrackingData = {
    subunit_type: 'component',
    subunit_name: `${objectType}|${contentObjectId}`,
  };

  const sharedTrackingData = {
    ...algorithmTrackingData,
    ...subunitTrackingData,
    ...commonTrackingData,
    position_in_unit: index,
    target_content_type: 'buzz',
    item_name: slide.content.id,
    target_content_id: slide.content.id,
  };

  const { setObservable } = useViewImpression({ trackingData: sharedTrackingData });

  return (
    <div
      className={`${styles.sliderWrapper} ${className}`}
      ref={obvEl => setObservable(obvEl)}
    >
      <div className={styles.slider}>
        <div className="each-slide">
          <div className={styles.slideContent}>
            <div className={styles.imageWrapper}>
              <a href={slide?.content?.url} onClick={() => trackInternalLink(sharedTrackingData)}>
                <img
                  className={!!slide?.content?.thumbnail?.standard.url?.length ? '' : styles.imagePlaceholder}
                  src={slide?.content?.thumbnail?.standard.url || ImagePlaceholder}
                  alt={slide?.content?.thumbnail?.standard.alt}
                  loading="lazy"
                />
              </a>
            </div>
            <div className={styles.textWrapper}>
              <span className={styles.nextGenGames}>{slide?.content?.caption}</span>
              <a href={slide?.content?.url} onClick={() => trackInternalLink(sharedTrackingData)}>
                <h3 className={styles.title}>{slide?.content.title}</h3>
              </a>
              <p className={styles.description}>{slide?.content.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewInArcade = ({ slides, trackingData }) => {
  const { trackContentAction } = useTrackingContext();
  const screenSize = useMediaQuery();
  const slideRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [SlideComponent, setSlideComponent] = useState(null);

  useEffect(() => {
    if (screenSize.isDesktop) {
      Promise.all([
        import('react-slideshow-image'),
        import('react-slideshow-image/dist/styles.css'),
      ]).then(([module]) => {
        setSlideComponent(() => module.Slide);
      });
    }
  }, [screenSize.isDesktop]);

  const goToPrevious = () => {
    if (slideRef.current) {
      slideRef.current.goBack();

      trackContentAction({
        ...trackingData,
        item_name: 'scroll',
        item_type: 'button',
        action_type: 'select',
        action_value: 'left',
      });
    }
  };

  const goToNext = () => {
    if (slideRef.current) {
      slideRef.current.goNext();

      trackContentAction({
        ...trackingData,
        item_name: 'scroll',
        item_type: 'button',
        action_type: 'select',
        action_value: 'right',
      });
    }
  };

  const goToSlide = (index) => {
    if (slideRef.current) {
      slideRef.current.goTo(index);
      setCurrentSlide(index);
    }
  };

  if (!slides.length) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.arcadeHeader}>New in the Arcade</h2>
      {screenSize.isDesktop && SlideComponent ? (
        <div className={styles.zoneWrapperDesktop}>
          <SlideComponent
            easing="ease"
            arrows={false}
            autoplay={false}
            infinite={slides.length > 1}
            indicators={false}
            ref={slideRef}
            onChange={(previous, next) => setCurrentSlide(next)}
          >
            {slides.map((slide, index) => (
              <BFSlide
                key={index}
                index={index}
                slide={slide}
                commonTrackingData={trackingData}
                className={''}
              />
            ))}
          </SlideComponent>
        </div>
      ) : (
        <div className={styles.mobileSlider}>
          {slides.map((slide, index) => (
            <BFSlide
              key={index}
              index={index}
              slide={slide}
              commonTrackingData={trackingData}
              className={styles.mobileSlide}
            />
          ))}
        </div>
      )}
      {slides.length > 1 && (
        <div className={styles.controls}>
          <div className={styles.arrowsContainer}>
            <button className={`${styles.nav} prev`} onClick={goToPrevious}><CaretLeftIcon /></button>
            <button className={`${styles.nav} next`} onClick={goToNext}><CaretRightIcon /></button>
          </div>
          <div className={styles.dotsContainer}>
            {slides.map((_, index) => (
              <button
                key={index}
                className={`${styles.dot} ${currentSlide === index ? styles.active : ''}`}
                onClick={() => goToSlide(index)}
              ></button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewInArcade;
