export const getStreakCount = (gameData, lastSeenKey = 'lastSeen') => {
  if (!gameData) return 0;
  
  const lastPlayed = new Date(gameData[lastSeenKey]);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);
  lastPlayed.setHours(0, 0, 0, 0);

  return lastPlayed >= yesterday ? gameData.currentStreak : 0;
}; 